import React, { useState, useEffect, useRef } from 'react';
import './LandingPage.css';
import video from '../../images/remodel_automation_mekaniikka.mp4';

const LandingPage = () => {
  const [isHidden, setIsHidden] = useState(false);
  const [isFadedOut, setIsFadedOut] = useState(false);
  const videoRef = useRef(null);

  const handleVideoClick = () => {
    setIsHidden(true);
  };

  const handleArrowClick = () => {
    window.scrollBy({
      top: window.innerHeight,
      left: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    let timeout;
    if (isHidden) {
      timeout = setTimeout(() => {
        setIsFadedOut(true);
        if (videoRef.current) {
          videoRef.current.pause();
        }
      }, 1600); // Odota, että video häipyy pois (1600ms)
    } else if (isFadedOut) {
      timeout = setTimeout(() => {
        setIsFadedOut(false);
        const videoElement = videoRef.current;
        if (videoElement) {
          videoElement.currentTime = 0; // Aseta video alkuun
          videoElement.play();
        }
      }, 0);
    }
    return () => clearTimeout(timeout);
  }, [isHidden, isFadedOut]);

  useEffect(() => {
    if (isFadedOut) {
      setIsHidden(false);
    }
  }, [isFadedOut]);

  return (
    <div className="landing-page">
      <div className="video-container">
        <video
          ref={videoRef}
          className={isHidden ? 'hidden' : ''}
          autoPlay
          muted
          playsInline
          onEnded={() => videoRef.current && videoRef.current.pause()} // Pysäytä automaattinen looppaus videon lopussa
          onClick={handleVideoClick}
        >
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="arrow" onClick={handleArrowClick}>⇓</div>
    </div>
  );
};

export default LandingPage;
